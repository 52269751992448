import React from 'react'
import Helmet from 'react-helmet'
import PageHeader from 'components/PageHeader'
import { Grid, Row, Col } from 'react-flexbox-grid'
import { Paragraph } from 'components/Typography'
import styles from './index.module.less'
import image1 from 'images/project-14a.png'
import image2 from 'images/project-14b.png'
import image3 from 'images/project-14c.png'
import image4 from 'images/project-14d.png'
import Layout from 'components/Layout'

const Page = () => (
  <Layout>
    <Helmet
      title="Whānau roll up their sleeves"
      meta={[
        {
          name: 'description',
          content: '',
        },
      ]}
    >
      <html lang="en" />
    </Helmet>
    <PageHeader
      title="Whānau roll up their sleeves"
      intro="In December 2020, the Trust helped a local Porirua family to renovate their home."
    />
    <Grid fluid>
      <Row>
        <Col sm={8} className={styles.projectText}>
          <Paragraph>
            The work included installing a new kitchen, upgrading the bathroom, laying new floor coverings, upgrading the electrical system and providing exterior handrails.
          </Paragraph>
          <Paragraph>
            With help from Dulux, the family repainted the interior. They were so excited to be learning new skills and look forward to the next step &mdash; repainting the exterior.
          </Paragraph>
          <Paragraph>
            Mum, Chalajia, feels that none of this work would have been possible before the Trust stepped in. She is amazed by the transformation and grateful for the Trust&rsquo;s assistance.
          </Paragraph>
          <Paragraph>
            In the past, her two sons would ask if they could get a new home. &ldquo;There&rsquo;s none of that talk these days,&rdquo; she says. With the new carpet, the family have already noticed how much warmer the home feels and the feel very blessed.
          </Paragraph>
          <figure>
            <img className={styles.image} src={image4} alt="" />
            <figcaption>Newly refurbished living room</figcaption>
          </figure>
        </Col>
        <Col sm={4} className={styles.projectImages}>
          <img className={styles.image} src={image1} alt="" />
          <figure>
            <img className={styles.image} src={image2} alt="" />
            <figcaption>Bathroom before</figcaption>
          </figure>
          <figure>
            <img className={styles.image} src={image3} alt="" />
            <figcaption>Bathroom after</figcaption>
          </figure>
        </Col>
      </Row>
    </Grid>
  </Layout>
)

export default Page
